import React, { useContext, useState } from "react";
import { Container, Row, Col, ProgressBar } from "react-bootstrap";
import styled from "styled-components";
import { useHistory, Redirect } from "react-router-dom";
import ReactGA from "react-ga";
import map from "async/map";
import waterfall from "async/waterfall";
import { AppContext } from "./../../AppContext";
import Header from "./../../components/Header";
import LOGO from "./../../assets/logo.png";
import HOMEIMG from "./../../assets/bienvenida.jpg";
import { AppButton } from "./../../components/Button";
import { getFile, getImage, getFileTest, getImageTest } from "./../../services";
const pLimit = require('p-limit');
const limit = pLimit(10);
const ContainerWrapper = styled(Container)`
  margin-bottom: 30px;
`;
const Div = styled.div`
  margin-top: 130px;
  /* margin-bottom: 60px; */
  h1 {
    font-family: Armata-Regular;
    font-size: 22px;
    text-align: center;
  }
  & {
    @media screen and (max-width: 600px) {
      margin-top: 110px;
    }
  }
`;
const RowW = styled(Row)`
  padding: 0;
  margin: 0;
  /* background-color: #f2f1ee !important; */
`;
const IMG = styled.img`
  width: 100px;
  height: 100px;
`;

const Languages = () => {
  let history = useHistory();
  const { languages, setLanguage, collections, setCollections } = useContext(
    AppContext
  );

  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);

  const selectLanguage = async (item) => {
    setLanguage(item);
    runGA(item);
    await loadData(item);
  };
  const processObra = async (obra, lang, collection, callback) => {
    try {
      console.log('call obra file');
      const response = await getFile(obra.audio[lang.abreviatura]);
      console.log('obra file response');
      const url = URL.createObjectURL(response.data);
      obra.audioUrl = url;
      return callback();
    } catch (error) {
      console.log("----------------------------------");
      console.log(collection.nombre[lang.abreviatura]);
      console.log(obra.titulo[lang.abreviatura]);
      console.log("----------------------------------");
      return callback();
    }
  };
  const loadData = async (lang) => {
    const _collections = collections;
    let total = 0;
    let count = 0;
    for (let i = 0; i < collections.length; i++) {
      for (let j = 0; j < collections[i].obras.length; j++) {
        if (collections[i].obras[j].audio[lang.abreviatura]) {
          total++;
        }
        // if (collections[i].obras[j].imagen_id) {
        //   total++;
        // }
      }
    }
    setLoading(true);
    const callback = ()=>{
            count++;
            setPercent(parseInt((count * 100) / total));
    }
    try {
      let promises = [];
      for (let collectionIndex = 0; collectionIndex < _collections.length; collectionIndex++) {
        const collection = _collections[collectionIndex];
        const list = collection.obras.map((obra)=>{
          return limit(()=>processObra(obra, lang, collection, callback));
        });
        promises = promises.concat(list)
      }
      console.log('promises', promises);
      await Promise.all(promises);
      history.push("/");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const runGA = (item) => {
    ReactGA.event({
      category: "Language",
      action: `Selected -${item.nombre}`,
      value: parseInt(item.id),
    });
  };
  
  return (
    <React.Fragment>
      <Header />
      <Div>
        <h1>Audioguía</h1>
      </Div>
      {!loading && (
        <ContainerWrapper>
          <Row>
            <Col>
              <img className="w-100" src={HOMEIMG} alt="" />
            </Col>
          </Row>
          {languages.length < 1 && (
            <Row>
              <Col className="d-flex justify-content-center"></Col>
            </Row>
          )}
          {languages.length > 0 &&
            languages.map((item) => {
              return (
                <Row key={`lang-${item.id}`} className="mt-4">
                  <Col className="d-flex justify-content-center">
                    <AppButton
                      onClick={async () => {
                        await selectLanguage(item);
                      }}
                    >
                      <span>{item.nombre}</span>&nbsp;
                    </AppButton>
                  </Col>
                </Row>
              );
            })}
        </ContainerWrapper>
      )}

      {loading && (
        <ContainerWrapper>
          <RowW>
            <Col
              style={{ marginTop: 120 }}
              className="d-flex justify-content-center align-items-center"
            >
              <div>
                <IMG src={LOGO} alt="" />
              </div>
            </Col>
          </RowW>
          <Row>
            <Col className="p-5">
              Cargando Datos ... <span>({`${percent}%`})</span>
              <ProgressBar now={percent} label={`${percent}%`} srOnly />
            </Col>
          </Row>
        </ContainerWrapper>
      )}
    </React.Fragment>
  );
};

export default Languages;
